import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateMeasurementMappingRequest,
  MutationCreateMeasurementMappingArgs,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../../components/forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useCreateMeasurementMappingMutation } from '../../../graphql/hooks/useCreateMeasurementMappingMutation';
import { useAppContext } from '../../../hooks/useAppContext';

type FormValues = CreateMeasurementMappingRequest;

export const MeasurementMappingCreateModal = createModal((props) => {
  const { modal } = props;
  const { successToast, errorToast } = useAppContext();
  const mutation = useCreateMeasurementMappingMutation();

  const initialValues = {
    name: '',
    shortName: null,
    site: '',
    units: null,
    precision: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    shortName: Yup.string().nullable(),
    site: Yup.string().required('Site is required'),
    units: Yup.string().nullable(),
    precision: Yup.number().nullable(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      const request: MutationCreateMeasurementMappingArgs = {
        request: values,
      };

      mutation
        .mutateAsync(request)
        .then(() => {
          successToast('Measurement mapping created successfully');
          modal.hide();
        })
        .catch((err) => {
          errorToast(`Error creating measurement mapping: ${err.message}`);
        });
    },
    [mutation, successToast, errorToast, modal],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create measurement mapping</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack>
                <HookedFormItem label="Name" name="name">
                  <HookedInput
                    name="name"
                    placeholder="Mapping name (eg. Left Ventricular End Diastolic Dimension)"
                  />
                </HookedFormItem>
                <HookedFormItem label="Short name" name="shortName">
                  <HookedInput name="shortName" placeholder="Mapping short name (eg. LVEDD)" />
                </HookedFormItem>
                <HookedFormItem label="Site" name="site">
                  <HookedInput name="site" placeholder="Mapping site (eg. Left Ventricle)" />
                </HookedFormItem>
                <HookedFormItem label="Units" name="units">
                  <HookedInput name="units" placeholder="Mapping units (eg. mm)" />
                </HookedFormItem>
                <HookedFormItem label="Precision" name="precision">
                  <HookedInput name="precision" placeholder="Mapping prevision (eg. 2)" />
                </HookedFormItem>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button key="close" onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
