import { Stack } from '@chakra-ui/react';
import React from 'react';
import { ImageViewerModeType } from '../controls/ModeControl';
import { UseImageViewerActionsReturn } from '../hooks/useImageViewerActions';
import { InstanceThumbnail } from './InstanceThumbnail';

interface Props {
  actions: UseImageViewerActionsReturn;
}

export const ImageViewerSidebar = React.memo((props: Props) => {
  const { actions } = props;

  const thumbs = actions.instances.map((instanceStack, i) => {
    const firstInstance = instanceStack.instances[0];
    const isVisible = actions.viewports.some((viewport) =>
      viewport.stack.instances.includes(firstInstance),
    );
    const index = actions.mode.type === ImageViewerModeType.Normal ? i : undefined;

    const count =
      actions.mode.type === ImageViewerModeType.Normal
        ? actions.instances.length
        : instanceStack.instances.length;

    const onClickThumb = () => actions.clickInstanceStack(instanceStack);

    return (
      <InstanceThumbnail
        key={firstInstance.id}
        instance={firstInstance}
        index={index}
        count={count}
        isVisible={isVisible}
        onClick={onClickThumb}
      />
    );
  });

  return (
    <Stack spacing={3} py={2} pr={3}>
      {thumbs}
    </Stack>
  );
});
