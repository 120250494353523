import { Box, Grid, HStack, Stack } from '@chakra-ui/react';
import { LooseObject, Study } from '@piccolohealth/echo-common';
import { Empty, ScrollArea, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useMeasure } from 'react-use';
import { useReportStudyInstancesQuery } from '../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { Error } from '../generic/Error';
import { ImageViewerControls } from './controls/ImageViewerControls';
import { layoutToStyle } from './controls/Layout';
import { ImageViewerActionsOptions, useImageViewerActions } from './hooks/useImageViewerActions';
import { ImageViewerReportSlideOut } from './ImageViewerReportSlideOut';
import { Player } from './player/Player';
import { ImageViewerSidebar } from './sidebar/ImageViewerSidebar';

export interface ImageViewerOptions {
  allowFullscreen?: boolean;
  allowChangeModes?: boolean;
  allowChangeLayout?: boolean;
  allowShowReport?: boolean;
  onScreenshot?: (value: string, metadata: LooseObject) => void;
}

type Options = ImageViewerOptions & ImageViewerActionsOptions;

interface ImageViewerInternalProps extends Options {
  study: Study;
}

const ImageViewerInternal = (props: ImageViewerInternalProps) => {
  const { study, ...options } = props;
  const imageViewerActions = useImageViewerActions(study, options);
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  if (P.isEmpty(imageViewerActions.instances)) {
    return <Empty title="No images" description="There were no images uploaded for this study" />;
  }

  const viewport = (
    <Grid
      ref={imageViewerActions.gridRef}
      w="full"
      h="full"
      minH="0"
      userSelect="none"
      bg="black"
      {...layoutToStyle(imageViewerActions.layout)}
    >
      {imageViewerActions.viewports.map((viewport, index) => {
        return (
          <Player
            key={`viewport-${index}`}
            gridIndex={index}
            viewport={viewport}
            actions={imageViewerActions}
            onScreenshot={options.onScreenshot}
          />
        );
      })}
    </Grid>
  );

  return (
    <Box ref={ref} w="full" h="full" bg="black" userSelect="none">
      <HStack ref={imageViewerActions.containerRef} h="full" w="full" spacing={0} bg="black">
        {imageViewerActions.isFullscreen && <Box id="imageviewer-portal" />}

        <ScrollArea flexShrink={0}>
          <ImageViewerSidebar actions={imageViewerActions} />
        </ScrollArea>
        <Stack
          w="full"
          h="full"
          flexDir={imageViewerActions.toolbarPosition === 'top' ? 'column' : 'column-reverse'}
          flexGrow={1}
          spacing={2}
          justify="start"
          align="start"
          bg="black"
        >
          <ImageViewerControls options={options} actions={imageViewerActions} />
          <HStack minH="0" w="full" h="full" bg="black">
            {viewport}
            <ImageViewerReportSlideOut
              parentWidth={width}
              isReportShowing={imageViewerActions.isReportShowing}
              allowShowReport={options.allowShowReport ?? false}
            />
          </HStack>
        </Stack>
      </HStack>
    </Box>
  );
};

interface Props extends Options {
  reportId: string;
}

export const ImageViewer = (props: Props) => {
  const { reportId, allowShowReport, ...rest } = props;
  const { organization } = useAppContext();

  const { isLoading, data, error } = useReportStudyInstancesQuery({
    organizationId: organization.id,
    reportId: reportId,
  });

  const report = data?.organization?.report;
  const study = report?.study as Study | undefined;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (P.isNil(study) || P.isNil(report)) {
    return <Empty title="No study" description="There was no study found for this report" />;
  }

  const options: ImageViewerOptions = {
    ...rest,
    allowShowReport: allowShowReport ?? true,
  };

  return <ImageViewerInternal study={study} {...options} />;
};
