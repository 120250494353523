import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { intersperseMenuItems, Permission, PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import {
  FaAngleDown,
  FaChartBar,
  FaCog,
  FaDollarSign,
  FaFileMedicalAlt,
  FaHistory,
  FaKey,
  FaSignOutAlt,
  FaUser,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useCreatePasswordChangeTicketUrlMutation } from '../../graphql/hooks/useCreatePasswordChangeTicketUrlMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { VisibleWithPermission } from '../generic/VisibleWithPermission';
import { UserAvatar } from '../user/UserAvatar';

const UserMenuItem = (props: { permission: Permission; to: string } & MenuItemProps) => {
  const { permission, ...rest } = props;
  const hasPermission = usePermission(permission).value;

  return <MenuItem isDisabled={!hasPermission} as={Link} {...rest} />;
};

const UserSubMenu = () => {
  const { user, organization, config, auth } = useAppContext();

  const mutation = useCreatePasswordChangeTicketUrlMutation();

  const changePassword = async () => {
    const resp = await mutation.mutateAsync({});
    window.location.href = resp.createPasswordChangeTicketUrl;
  };

  const items = intersperseMenuItems(
    [
      [
        <UserMenuItem
          key="profile"
          icon={<FaUser />}
          to={`/organizations/${organization.id}/profile`}
          permission={PERMISSIONS.organizationRead}
          data-pw="profileMenuItem"
        >
          Profile
        </UserMenuItem>,
        <UserMenuItem
          key="settings"
          icon={<FaCog />}
          to={`/organizations/${organization.id}/settings`}
          permission={PERMISSIONS.organizationUpdate}
          data-pw="settingsMenuItem"
        >
          Settings
        </UserMenuItem>,
        <UserMenuItem
          key="insights"
          icon={<FaChartBar />}
          permission={PERMISSIONS.organizationUpdate}
          to={`/organizations/${organization.id}/insights`}
        >
          Insights
        </UserMenuItem>,
        <UserMenuItem
          key="billing"
          icon={<FaDollarSign />}
          permission={PERMISSIONS.organizationUpdate}
          to={`/organizations/${organization.id}/billing/invoices`}
        >
          Billing
        </UserMenuItem>,
        <VisibleWithPermission key="templates" permission={PERMISSIONS.organizationSupport}>
          <UserMenuItem
            icon={<FaFileMedicalAlt />}
            to={`/organizations/${organization.id}/templates`}
            permission={PERMISSIONS.organizationSupport}
          >
            Report Templates
          </UserMenuItem>
        </VisibleWithPermission>,
        <UserMenuItem
          key="audit-trail"
          icon={<FaHistory />}
          to={`/organizations/${organization.id}/audit-trail`}
          permission={PERMISSIONS.organizationUpdate}
        >
          Audit Trail
        </UserMenuItem>,
      ],
      [
        <MenuItem key="change-password" icon={<FaKey />} onClick={changePassword}>
          Reset password
        </MenuItem>,
        <MenuItem
          key="logout"
          icon={<FaSignOutAlt />}
          onClick={() => auth.logout({ returnTo: config.auth.logoutRedirectUri })}
        >
          Logout
        </MenuItem>,
      ],
    ],
    (n: number) => <MenuDivider key={`divider-${n}`} />,
  );

  return (
    <Menu isLazy lazyBehavior="keepMounted" data-pw="userMenu">
      <HStack spacing={0} color="white">
        <UserAvatar picture={user.picture} name={user.name} size="sm" mr={3} mt={0} />
        <Button
          as={MenuButton}
          variant="link"
          color="white"
          _active={{ color: 'white' }}
          _hover={{ color: 'white', textDecoration: 'underline' }}
          data-pw="userMenuButton"
        >
          <Text>{user.email}</Text>
        </Button>
        <FaAngleDown />
      </HStack>
      <MenuList key="userMenu">{items}</MenuList>
    </Menu>
  );
};

export const UserMenu = () => {
  return (
    <Menu isLazy lazyBehavior="keepMounted" data-pw="userMenu">
      <UserSubMenu />
    </Menu>
  );
};
