import { Stage, View } from '@piccolohealth/echo-common';

export const STAGE_ORDER = [Stage.Unknown, Stage.Rest, Stage.Post];

export const VIEW_ORDER = [
  View.Plax,
  View.PsaxBasal,
  View.PsaxMid,
  View.PsaxApical,
  View.Ap4,
  View.Ap3,
  View.Ap2,
];
