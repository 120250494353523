import gql from 'graphql-tag';

export const ReportMinimal = gql`
  query ReportMinimal($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        organizationId
        variables(
          aliases: [
            "patientName"
            "patientId"
            "dob"
            "age"
            "sex"
            "studyDate"
            "cardiologist"
            "sonographer"
          ]
        ) {
          __typename
          ... on ReportStaticVariable {
            id
            alias
            value
            units
          }
          ... on ReportChoiceVariable {
            id
            alias
            value
          }
          ... on ReportWallMotionVariable {
            id
            alias
            value
          }
          ... on ReportMediaAttachmentVariable {
            id
            alias
            value
          }
        }
        reportTemplate {
          id
          versionId
          name
          labelColor
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const ReportType = gql`
  query ReportType($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        status
        reportTemplate {
          id
          name
          isLatest
        }
      }
    }
  }
`;

export const ReportAttachments = gql`
  query ReportAttachments($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        organizationId
        attachments {
          id
          name
          uploader {
            id
            name
          }
          viewUrl
          downloadUrl
          createdAt
        }
      }
    }
  }
`;

export const ReportAttachmentsMinimal = gql`
  query ReportAttachmentsMinimal($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        attachments {
          id
        }
      }
    }
  }
`;

export const Report = gql`
  query Report($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        organizationId
        changesetId
        status
        variables {
          __typename
          ... on ReportStaticVariable {
            id
            alias
            value
            units
          }
          ... on ReportChoiceVariable {
            id
            alias
            value
          }
          ... on ReportWallMotionVariable {
            id
            alias
            value
          }
          ... on ReportMediaAttachmentVariable {
            id
            alias
            value
          }
        }
        statementSites {
          id
          statements {
            id
            value
          }
        }
        reportTemplate {
          id
          versionId
          billingActionId
          name
          reportNodes
          isLatest
          status
          labelColor
          statementSites {
            id
            name
            statements {
              id
              value
              default
            }
          }
          variables {
            __typename
            ... on ReportTemplateStaticVariable {
              id
              alias
              source
              label
              site
              category
              units
              precision
              controlType
              defaultValue
              isEditable
              mappings {
                id
                name
                shortName
              }
            }
            ... on ReportTemplateChoiceVariable {
              id
              alias
              source
              label
              site
              category
              choices {
                id
                label
                value
                default
              }
              controlType
              defaultValue
              isEditable
              mappings {
                id
                name
                shortName
              }
            }
            ... on ReportTemplateWallMotionVariable {
              id
              alias
              source
              label
              site
              category
              variant
              isEditable
              defaultValue
            }
            ... on ReportTemplateMediaAttachmentVariable {
              id
              alias
              source
              label
              category
              isEditable
              defaultValue
            }
          }
          createdAt
          updatedAt
        }
        labels {
          id
          name
          color
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const ReportRelatedVariables = gql`
  query ReportRelatedVariables($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        organizationId
        changesetId
        variables {
          __typename
          ... on ReportStaticVariable {
            id
            alias
            value
            units
          }
          ... on ReportChoiceVariable {
            id
            alias
            value
          }
          ... on ReportWallMotionVariable {
            id
            alias
            value
          }
          ... on ReportMediaAttachmentVariable {
            id
            alias
            value
          }
        }
        related {
          id
          variables {
            __typename
            ... on ReportStaticVariable {
              id
              alias
              value
              units
            }
            ... on ReportChoiceVariable {
              id
              alias
              value
            }
            ... on ReportWallMotionVariable {
              id
              alias
              value
            }
            ... on ReportMediaAttachmentVariable {
              id
              alias
              value
            }
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const ReportStudyInstancesMinimal = gql`
  query ReportStudyInstancesMinimal($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        study {
          instances {
            id
            dicom {
              modality
              transferSyntaxUID
              imageType
            }
          }
        }
      }
    }
  }
`;

export const ReportStudyInstances = gql`
  query ReportStudyInstances($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        organizationId
        reportTemplate {
          id
        }
        study {
          id
          organizationId
          healthcareRegion
          healthcareDatasetId
          instances {
            id
            studyId
            seriesId
            dicom {
              manufacturer
              model
              modality
              instanceNumber
              contentDateTime
              numberOfFrames
              numberOfStages
              stage
              view
              heartRate
              frameRate
              duration
              imageType
              transferSyntaxUID
            }
            fileUrl
            frameUrl
            mp4Url
            size
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        status
      }
    }
  }
`;

export const ReportPdfs = gql`
  query ReportPdfs($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        organizationId
        pdfs {
          version
          viewUrl
          downloadUrl
        }
      }
    }
  }
`;

export const ReportSharesMinimal = gql`
  query ReportSharesMinimal($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        shares {
          id
          shareeEmail
          messageDetails {
            status
            message
          }
          isExpired
          expiresAt
        }
      }
    }
  }
`;

export const ReportRelatedReports = gql`
  query ReportRelatedReports($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        organizationId
        related {
          id
          organizationId
          status
          reportTemplate {
            id
            name
            labelColor
          }
          variables(
            aliases: [
              "patientName"
              "patientId"
              "dob"
              "age"
              "sex"
              "studyDate"
              "cardiologist"
              "sonographer"
            ]
          ) {
            __typename
            ... on ReportStaticVariable {
              id
              alias
              value
              units
            }
            ... on ReportChoiceVariable {
              id
              alias
              value
            }
            ... on ReportWallMotionVariable {
              id
              alias
              value
            }
            ... on ReportMediaAttachmentVariable {
              id
              alias
              value
            }
          }
          labels {
            id
            name
            description
            color
            createdAt
            updatedAt
          }
          distributions {
            id
            organizationId
            reportId
            status
            trigger
            payload {
              __typename
              ... on DistributionMedicalObjectsPayload {
                to {
                  providerDetails {
                    id
                    firstName
                    middleName
                    lastName
                    prefix
                    suffix
                  }
                  providerNumber
                }
                from {
                  providerDetails {
                    id
                    firstName
                    middleName
                    lastName
                    prefix
                    suffix
                  }
                  providerNumber
                }
              }
              ... on DistributionHealthLinkPayload {
                to {
                  practitionerId
                  practitionerName {
                    text
                    family
                    given
                    prefix
                    suffix
                  }
                  organizationId
                  organizationName
                  organizationAddress {
                    city
                    country
                    line
                    postalCode
                    state
                    text
                    type
                    use
                  }
                  endpointId
                  endpointName
                  providerNumber
                }
                from {
                  providerNumber
                  name
                }
              }
              ... on DistributionEmailPayload {
                sharerId
                sharerEmail
                sharerName
                shareeEmail
              }
              ... on DistributionFaxPayload {
                to {
                  faxNumber
                }
                from {
                  sharerId
                  sharerEmail
                  sharerName
                }
              }
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ReportRelatedReportsMinimal = gql`
  query ReportRelatedReportsMinimal($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        related {
          id
        }
      }
    }
  }
`;

export const ReportDistributionsMinimal = gql`
  query ReportDistributionsMinimal($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        status
        distributions {
          id
          organizationId
          reportId
          status
          trigger
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ReportDistributions = gql`
  query ReportDistributions($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      report(reportId: $reportId) {
        id
        status
        distributions {
          id
          organizationId
          reportId
          status
          trigger
          events {
            id
            type
            payload
            createdAt
          }
          payload {
            __typename
            ... on DistributionMedicalObjectsPayload {
              to {
                providerDetails {
                  id
                  firstName
                  middleName
                  lastName
                  prefix
                  suffix
                  address {
                    class
                    streetAddress
                    otherDesignation
                    city
                    state
                    postCode
                    country
                    addressType
                    otherGeographicDesignation
                  }
                }
                providerNumber
              }
              from {
                providerDetails {
                  id
                  firstName
                  middleName
                  lastName
                  prefix
                  suffix
                  address {
                    class
                    streetAddress
                    otherDesignation
                    city
                    state
                    postCode
                    country
                    addressType
                    otherGeographicDesignation
                  }
                }
                providerNumber
              }
            }
            ... on DistributionHealthLinkPayload {
              to {
                practitionerId
                practitionerName {
                  text
                  family
                  given
                  prefix
                  suffix
                }
                organizationId
                organizationName
                organizationAddress {
                  city
                  country
                  line
                  postalCode
                  state
                  text
                  type
                  use
                }
                endpointId
                endpointName
                providerNumber
              }
              from {
                providerNumber
                name
              }
            }
            ... on DistributionEmailPayload {
              sharerId
              sharerEmail
              sharerName
              shareeEmail
              message
            }
            ... on DistributionFaxPayload {
              to {
                faxNumber
              }
              from {
                sharerId
                sharerEmail
                sharerName
              }
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
