import { Box } from '@chakra-ui/react';
import { LooseObject } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { InstanceFormat } from '../controls/InstanceFormatTag';
import { UseImageViewerActionsReturn, Viewport } from '../hooks/useImageViewerActions';
import { Overlay } from '../overlays/Overlay';
import { JpegPlayer } from './JpegPlayer';
import { Mp4Player } from './Mp4Player';

interface Props {
  gridIndex: number;
  viewport: Viewport;
  actions: UseImageViewerActionsReturn;
  onScreenshot?: (data: string, metadata: LooseObject) => void;
}

export const Player = (props: Props) => {
  const { gridIndex, viewport, actions, onScreenshot } = props;

  const { config } = useAppContext();

  let content = null;

  const stack = viewport.stack;
  const instance = stack.instances[stack.index ?? 0];
  const format = instance.mp4Url ? InstanceFormat.MP4 : InstanceFormat.JPEG;
  const total = viewport.total;

  if (format === InstanceFormat.MP4 && instance.mp4Url) {
    const options = {
      src: instance.mp4Url,
      isPlaying: actions.isPlaying,
      playbackRate: actions.speed,
      duration: instance.dicom.duration as number,
      frameRate: instance.dicom.frameRate as number,
      numberOfFrames: instance.dicom.numberOfFrames as number,
      metadata: instance.dicom,
      synchronizer: actions.synchronizer,
      onScreenshot,
    };

    content = <Mp4Player {...options} />;
  } else if (format === InstanceFormat.JPEG && instance.frameUrl) {
    const options = {
      id: instance.id,
      src: `${config.api.url}${instance.frameUrl}/${1}`,
      metadata: instance.dicom,
      onScreenshot,
    };

    content = <JpegPlayer {...options} />;
  }

  const instanceInfo = {
    index: stack.index ?? viewport.index,
    total,
    instance,
  };

  const preventMouseInteraction = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.preventDefault(),
    [],
  );

  return (
    <Box
      bg="black"
      position="relative"
      onContextMenu={preventMouseInteraction}
      onMouseDown={preventMouseInteraction}
      filter={`
        brightness(${actions.brightness}) contrast(${actions.contrast})
      `}
    >
      <Overlay
        gridIndex={gridIndex}
        format={format}
        instanceInfo={instanceInfo}
        layout={actions.layout}
        viewport={viewport}
        onClickInstance={actions.clickViewportInstance}
      />
      {content}
    </Box>
  );
};
