import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  MeasurementMapping,
  MutationUpdateMeasurementMappingArgs,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../../components/forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useAppContext } from '../../../hooks/useAppContext';
import { useUpdateMeasurementMappingMutation } from '../../../graphql/hooks/useUpdateMeasurementMappingMutation';

interface Props {
  measurementMapping: MeasurementMapping;
}

type FormValues = {
  name: string;
  site?: string | null;
  units?: string | null;
  precision?: number | null;
};

export const MeasurementMappingEditModal = createModal<Props>((props) => {
  const { measurementMapping, modal } = props;
  const { successToast, errorToast } = useAppContext();
  const mutation = useUpdateMeasurementMappingMutation();

  const initialValues = {
    name: measurementMapping.name,
    shortName: measurementMapping.shortName,
    site: measurementMapping.site,
    units: measurementMapping.units,
    precision: measurementMapping.precision,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    shortName: Yup.string().nullable(),
    site: Yup.string().nullable(),
    units: Yup.string().nullable(),
    precision: Yup.number().nullable(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      const request: MutationUpdateMeasurementMappingArgs = {
        mappingId: measurementMapping.id,
        request: values,
      };

      mutation
        .mutateAsync(request)
        .then(() => {
          successToast('Measurement mapping updated successfully');
          modal.hide();
        })
        .catch((err) => {
          errorToast(`Error updating measurement mapping: ${err.message}`);
        });
    },
    [mutation, successToast, errorToast, modal, measurementMapping.id],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit measurement mapping</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack>
                <HookedFormItem label="Name" name="name">
                  <HookedInput name="name" />
                </HookedFormItem>
                <HookedFormItem label="Short name" name="shortName">
                  <HookedInput name="shortName" />
                </HookedFormItem>
                <HookedFormItem label="Site" name="site">
                  <HookedInput name="site" />
                </HookedFormItem>
                <HookedFormItem label="Units" name="units">
                  <HookedInput name="units" />
                </HookedFormItem>
                <HookedFormItem label="Precision" name="precision">
                  <HookedInput name="precision" />
                </HookedFormItem>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button key="close" onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
